<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-10 text-center">
        <h1>Oops, something went wrong</h1>
        <div class="row">
            <div class="col col-lg-2">
                <!-- Leave empty-->
            </div>
            <div class="text-center col col-lg-8">
                You arrived here due to an error, but it's on us. Please try again soon, or now. If error persists, feel free to contact us.
                <div class="text-center mt-4">
        <router-link class="btn btn-md btn-info" to="/accomplishments/new">
          <i ref="about" class="fas fa-download mr-2"></i>
          Create Certificate Now!
        </router-link>
        or
        <router-link class="btn btn-md btn-primary" to="/contact">
          <i ref="about" class="fas fa-envelope mr-2"></i>
          Contact
        </router-link>
      </div>
            </div>
            <div class="col col-lg-2">
                <!--Leave empty-->
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
