import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CreateCertificateView from '../views/CreateCertificateView.vue'
import ContactView from '../views/ContactView.vue'
import CertificateView from '../views/CertificateView.vue'
import ErrorView from '../views/ErrorView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/accomplishments/new',
    name: 'newCert',
    component: CreateCertificateView
  },
  {
    path: '/accomplishments/:id',
    name: 'certificates/single',
    component: CertificateView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
