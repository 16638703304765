<template>
  <footer class="footer text-center">
    <div class="container">
      <div class="row mb-4">
        <div id="share"></div>
        <div class="col-md-6 mb-5 mb-lg-0">
          <h4 class="text-uppercase mb-4">Share the cert</h4>
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                class="
                  btn btn-outline-light btn-social
                  text-center
                  rounded-circle
                "
                :href="fbShare"
              >
                <i class="fab fa-fw fa-facebook-f"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="
                  btn btn-outline-light btn-social
                  text-center
                  rounded-circle
                "
                :href="twitterShare"
              >
                <i class="fab fa-fw fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="
                  btn btn-outline-light btn-social
                  text-center
                  rounded-circle
                "
                :href="linkedinShare"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <h4 class="text-uppercase mb-4">About acertified.guru</h4>
          <p class="lead mb-0">Why do when you can certificate.</p>
        </div>
      </div>
    </div>
    <div class="copyright py-4 text-center text-white">
      <div class="container">
        <small>&copy; acertified.guru 2022 | v0.0.3</small>
      </div>
    </div>
    <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) -->
    <div class="scroll-to-top d-lg-none position-fixed">
      <a
        class="js-scroll-trigger d-block text-center text-white rounded"
        href="#page-top"
      >
        <i class="fa fa-chevron-up"></i>
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterNavigation',
  data () {
    return {
      linkedinShare: `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
      twitterShare: `http://twitter.com/share?text=I just completed this amazing certification!&url=${window.location.href}&hashtags=certifiedguru`,
      fbShare: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=I just completed this amazing certification!`
    }
  }
}
</script>
