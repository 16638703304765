<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-10 text-center">
        <h1>Contact</h1>
        Reach us for inquiries, feedback, compliments, reports for abuse, requests for removal and anything in-between at info@acertified.guru
      </div>
    </div>
  </div>
</template>
