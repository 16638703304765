<template>
  <section class="bg-primary text-white mb-0" id="about">
    <div class="container">
      <h2 class="text-center text-uppercase text-white">About</h2>
      <hr class="star-light mb-5" />
      <div class="row">
        <div class="col-lg-4 ml-auto">
          <p class="lead">
            Be your own doctor, lawyer, blockchain or chairs specialist, today
          </p>
        </div>
        <div class="col-lg-4 mr-auto">
          <p class="lead">
            Earn a new, quick and shiny certification just before that interview
          </p>
        </div>
         <div class="col-lg-4 mr-auto">
          <p class="lead">
            No recertification required. Expires never.
          </p>
        </div>
      </div>
      <div class="text-center mt-4">
        <router-link class="btn btn-xl btn-outline-light" to="/accomplishments/new">
          <i ref="about" class="fas fa-download mr-2"></i>
          Create Certificate Now!
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'AboutSection'
}
</script>
