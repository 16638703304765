<template>
  <Transition>
  <section v-if="isMounted" class="portfolio mt-1" id="portfolio">
    <h2 class="text-center text-uppercase text-secondary mb-0">Featured Certs</h2>
    <hr class="star-dark mb-5">
    <div class="d-inline-block container-md">
      <div class="row">
        <div class="col"></div>
        <div class="col-lg-8">
          <div v-if="isLoaded" id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div v-for='(cert, index) in certs' :key='cert.certificateId'
                :class="{ 'carousel-item': true, 'active': index === active }">
                <img src="/img/frame.png" class="carouselImage img-fluid" alt="">
                <div class="carousel-caption">
                  <div class="container pm-certificate-container">
                    <div class="pm-certificate-border">
                      <div class="pm-certificate-inner-border">
                        <div class="pm-certificate-header">
                          <div class="pm-certificate-title cursive col-xs-12 text-center">
                            <h2 class="certTitleHeader">Certificate of Completion</h2>
                          </div>
                        </div>
                        <div class="pm-certificate-body">
                          <div class="pm-certificate-block">
                            <div class="">
                              <div class="col-xs-2">
                                <!-- LEAVE EMPTY -->
                              </div>
                              <div class="
                    pm-certificate-name
                    underline
                    margin-0
                    col-xs-8
                    text-center
                  ">
                                <span class="pm-name-text bold" type="text">{{ cert.name }}</span>
                              </div>
                              <div class="col-xs-2">
                                <!-- LEAVE EMPTY -->
                              </div>
                            </div>
                            <div class="col-xs-12">
                              <div class="">
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                                <div class="pm-earned col-xs-8 text-center">
                                  <span class="pm-earned-text padding-0 block cursive">has earned</span>
                                  <span class="pm-credits-text bold sans" type="text">{{ cert.certCredit }}</span>
                                </div>
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                                <div class="col-xs-12"></div>
                              </div>
                            </div>
                            <div class="col-xs-12">
                              <div class="">
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                                <div class="pm-course-title col-xs-8 text-center">
                                  <span class="pm-earned-text block cursive">while completing the training course
                                    entitled</span>
                                </div>
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                              </div>
                            </div>

                            <div class="col-xs-12">
                              <div>
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                                <div class="pm-course-title underline col-xs-8 text-center">
                                  <span class="pm-credits-text bold sans" type="text">{{ cert.certTitle }}</span>
                                </div>
                                <div class="col-xs-2">
                                  <!-- LEAVE EMPTY -->
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="container">
                            <div class="row">
                              <div class="col pm-certified text-center">
                                <span class="pm-credits-text sans" type="text">{{ cert.certSchoolName }}</span>
                                <span class="pm-empty-space block underline"></span>
                                <span class="bold" type="text">{{ cert.certInstructorName }}</span>
                              </div>
                              <div class="col">
                                <!-- empty -->
                              </div>
                              <div class="col pm-certified text-center">
                                <span class="pm-credits-text block sans">Date Completed</span>
                                <span class="pm-empty-space block underline"></span>
                                <span class="pm-credits-text sans">{{ cert.certDate }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button style="color: red;" class="carousel-control-prev" type="button"
              data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <div v-else class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </section>
  </Transition>
</template>
<script>
export default {
  name: 'FeaturedSection',
  data: () => ({
    active: 0,
    featured: 3,
    certs: [],
    isLoaded: false,
    isMounted: false
  }),
  async mounted () {
    this.isMounted = true
    this.getRecentCertificates()
  },
  methods: {
    setActive (index) {
      let active = index
      if (index === this.featured) active = 0
      else if (index === -1) active = this.featured - 1
      this.active = active
    },
    async getRecentCertificates () {
      const cert = await this.axios.get(
        'https://e6gloqqot4.execute-api.eu-central-1.amazonaws.com/dev/certificates/'
      )
      this.isLoaded = true
      this.certs = cert.data
      console.log(cert.data)
    }
  }
}
</script>
