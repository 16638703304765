<template>
<Transition>
  <div v-if="isMounted" class="container pm-certificate-container mt-3 mb-3">
    <div class="pm-certificate-border">
      <div v-if="isLoaded" class="pm-certificate-inner-border">
          <div class="pm-certificate-header">
            <div class="pm-certificate-title cursive col-xs-12 text-center">
              <h2 class="certTitleHeader">Certificate of Completion</h2>
            </div>
          </div>
          <div class="pm-certificate-body">
            <div class="pm-certificate-block">
              <div class="row">
                <div class="col-md-1">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="
                    pm-certificate-name
                    underline
                    margin-0
                    col
                    md-10
                    text-center
                  ">
                  <span class="pm-name-text bold" type="text">{{ name }}</span>
                </div>
                <div class="col-md-1">
                  <!-- LEAVE EMPTY -->
                </div>
              </div>
              <div class="col-xs-12">
                <div class="">
                  <div class="col-xs-2">
                    <!-- LEAVE EMPTY -->
                  </div>
                  <div class="pm-earned col-xs-8 text-center">
                    <span class="pm-earned-text padding-0 block cursive">has earned</span>
                    <span class="pm-credits-text bold sans" type="text">{{ certCredit }}</span>
                  </div>
                  <div class="col-xs-2">
                    <!-- LEAVE EMPTY -->
                  </div>
                  <div class="col-xs-12"></div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="">
                  <div class="col-xs-2">
                    <!-- LEAVE EMPTY -->
                  </div>
                  <div class="pm-course-title col-xs-8 text-center">
                    <span class="pm-earned-text block cursive">while completing the training course entitled</span>
                  </div>
                  <div class="col-xs-2">
                    <!-- LEAVE EMPTY -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-1">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="pm-course-title underline col md-10 text-center">
                  <span class="pm-credits-text bold sans" type="text">{{ certName }}</span>
                </div>
                <div class="col-md-1">
                  <!-- LEAVE EMPTY -->
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col pm-certified text-center">
                  <span class="pm-credits-text sans" type="text">{{ certSchoolName }}</span>
                  <span class="pm-empty-space block underline"></span>
                  <span class="bold" type="text">{{ certInstructorName }}</span>
                </div>
                <div class="col">
                  <!-- empty -->
                </div>
                <div class="col pm-certified text-center">
                  <span class="pm-credits-text block sans">Date Completed</span>
                  <span class="pm-empty-space block underline"></span>
                  <span class="pm-credits-text sans">{{ certDate }}</span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div v-if="isLoaded == false" class="spinner-border text-secondary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  </Transition>
</template>
<script>
export default {
  name: 'CreateCertificateView',
  data () {
    return {
      name: '',
      certName: '',
      certInstructorName: '',
      certDate: '',
      certCredit: '',
      certSchoolName: '',
      isLoaded: false,
      isMounted: false
    }
  },
  async mounted () {
    this.isMounted = true
    this.getCertificate()
  },
  methods: {
    async getCertificate () {
      const cert = await this.axios.get(
        `https://e6gloqqot4.execute-api.eu-central-1.amazonaws.com/dev/certificates/${this.$route.params.id}`
      )
      this.isLoaded = true
      this.name = cert.data.name
      this.certName = cert.data.certTitle
      this.certCredit = cert.data.certCredit
      this.certSchoolName = cert.data.certSchoolName
      this.certDate = cert.data.certDate
      this.certInstructorName = cert.data.certInstructorName
      console.log(cert.data)
      console.log(this.certDate)
    }
  }
}
</script>
