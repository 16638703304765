<template>
    <featured-section></featured-section>
    <about-section></about-section>
</template>

<script>
// @ is an alias to /src
import AboutSection from '../components/AboutSection.vue'
import FeaturedSection from '../components/FeaturedSection.vue'

export default {
  name: 'HomeView',
  components: {
    AboutSection,
    FeaturedSection
  }
}
</script>
