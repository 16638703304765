<template>
  <Transition>
  <nav v-if="isMounted" class="navbar navbar-expand-lg bg-secondary text-uppercase" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="/">A Certified Guru</a>
      <button
        ref="menuBtn"
        class="
          navbar-toggler navbar-toggler-right
          text-uppercase
          bg-primary
          text-white
          rounded
        "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Menu
        <i class="fas fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1">
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/accomplishments/new"
              >Create</router-link
            >
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link
              class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
              to="/contact"
              >Contact</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  </Transition>
</template>

<script>

export default {
  name: 'HeaderNavigation',
  data: () => ({
    isMounted: false
  }),
  mounted () {
    this.isMounted = true
  }
}
</script>
