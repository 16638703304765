<template>
  <div class="container pm-certificate-container mt-3 mb-3">
    <div class="pm-certificate-border">
      <div class="pm-certificate-inner-border">
        <div class="pm-certificate-header">
          <div class="pm-certificate-title cursive col-xs-12 text-center">
            <h2 class="certTitleHeader">Certificate of Completion</h2>
          </div>
        </div>
        <div class="pm-certificate-body">
          <div class="pm-certificate-block">
            <div class="">
              <div class="col-xs-2">
                <!-- LEAVE EMPTY -->
              </div>
              <div class="
                    pm-certificate-name
                    underline
                    margin-0
                    col-xs-8
                    text-center
                  ">
                <input class="pm-name-text bold" type="text" id="name" v-model="cert.name" name="name"
                  placeholder="<Full name>" />
              </div>
              <div class="col-xs-2">
                <!-- LEAVE EMPTY -->
              </div>
            </div>
            <div class="col-xs-12">
              <div class="">
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="pm-earned col-xs-8 text-center">
                  <span class="pm-earned-text padding-0 block cursive">has earned</span>
                  <input class="pm-credits-text bold sans" type="text" v-model="cert.certCredit" id="certCredit"
                    name="certCredit" placeholder="<Number of Credits>" />
                </div>
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="col-xs-12"></div>
              </div>
            </div>

            <div class="col-xs-12">
              <div class="">
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="pm-course-title col-xs-8 text-center">
                  <span class="pm-earned-text block cursive">while completing the training course entitled</span>
                </div>
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
              </div>
            </div>

            <div class="col-xs-12">
              <div>
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
                <div class="pm-course-title underline col-xs-8 text-center">
                  <input class="pm-credits-text bold sans" type="text" id="certName" name="certName"
                    v-model="cert.certName" placeholder="<Cert / Degree name>" />
                </div>
                <div class="col-xs-2">
                  <!-- LEAVE EMPTY -->
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col pm-certified text-center">
                <input class="pm-credits-text sans" type="text" id="certSchoolName" name="certSchoolName"
                  v-model="cert.certSchoolName" placeholder="<School / Institute name>" />
                <span class="pm-empty-space block underline"></span>
                <input class="bold" type="text" id="certInstructorName" v-model="cert.certInstructorName"
                  name="certInstructorName" placeholder="<Instructor's name>" />
              </div>
              <div class="col">
                <!-- empty -->
              </div>
              <div class="col pm-certified text-center">
                <span class="pm-credits-text block sans">Date Completed</span>
                <span class="pm-empty-space block underline"></span>
                <input class="pm-credits-text sans" type="date" id="certDate" v-model="cert.certDate" name="certDate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showError" class="alert alert-danger" role="alert">
    {{ errorMsg }}
  </div>
  <div v-if="isLoading" class="spinner-border text-secondary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div v-else class="container block mt-1 mb-3"><button class="btn btn-primary btn-lg"
      @click="submitCertificate">Generate</button></div>
</template>

<script>
export default {
  name: 'CreateCertificateView',
  data () {
    return {
      cert: {
        name: '',
        certName: '',
        certInstructorName: '',
        certDate: '',
        certCredit: '',
        certSchoolName: ''
      },
      isLoading: false,
      showError: false,
      errorMsg: ''
    }
  },
  methods: {
    async submitCertificate () {
      this.isLoading = true
      this.showError = false
      if (!this.hasEmptyValues(this.cert)) {
        await this.axios.post(
          'https://e6gloqqot4.execute-api.eu-central-1.amazonaws.com/dev/certificates', {
            name: this.cert.name,
            certName: this.cert.certName,
            certInstructorName: this.cert.certInstructorName,
            certDate: this.cert.certDate,
            certCredit: this.cert.certCredit,
            certSchoolName: this.cert.certSchoolName
          }
        ).then((response) => {
          console.log(response)
          this.isLoading = false
          // window.location.replace(`/accomplish/created/${taskResults.data.taskId}`)
          this.$router.push({ path: `/accomplishments/${response.data.certId}` })
        }).catch((error) => {
          this.isLoading = false
          this.showError = true
          this.errorMsg = 'Wrong data provided, or we are having trouble with our servers. Please try again.'
          console.log(error)
        })
      } else {
        this.isLoading = false
        this.showError = true
        this.errorMsg = 'Some fields are missing'
      }
    },
    hasEmptyValues (payload) {
      for (var key in payload) {
        if (payload[key] === '') {
          return true
        }
      }
      return false
    }
  }
}
</script>
