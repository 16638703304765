<template>
  <header-navigation></header-navigation>
  <router-view/>
  <footer-section></footer-section>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import FooterSection from './components/FooterSection.vue'
export default {
  components: {
    HeaderNavigation,
    FooterSection
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
